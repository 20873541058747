@import '../../../../../../assets/styles/vars.less';

.rr-pledge {
    display: flex;
    flex-direction: column;
}
.rr-pledge-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 4px;
}

.rr-pledge-actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 18px;
}

.rr-pledge-field {
    display: flex;
    align-items: center;
    min-height: 38px;

    .rr-dd-i {
        width: 100%;
    }

    .rr-inline-editable {
        display: block;
    }

    input,
    textarea {
        font-size: 18px !important;
        font-weight: 600;
        width: 100%;
    }

    textarea {
        resize: none;
        field-sizing: content;
        height: auto;
    }
}

.requiredPledgeSum {
    font-size: 14px;
    display: flex;
    margin-bottom: 8px;

    & .requiredPledgeSum-success {
        color: @rr-color-available;
    }

    & .requiredPledgeSum-problem {
        color: @rr-color-danger;
        font-weight: 600;
    }
}

@font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';